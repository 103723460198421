@import "@/app/styles/_mantine.scss";
.auth-loader {
	background: rgba(255, 255, 255, 20%);
	backdrop-filter: blur(1px);
	z-index: 999999;
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
