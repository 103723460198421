@use 'sass:math';

// Define variables for your breakpoints,
// values must be the same as in your theme
$mantine-breakpoint-xs: '36em';
$mantine-breakpoint-sm: '48em';
$mantine-breakpoint-md: '62em';
$mantine-breakpoint-lg: '75em';
$mantine-breakpoint-xl: '88em';

$desktop-inline-padding: 40px;
$desktop-block-padding: 24px;
$mobile-inline-padding: 16px;
$mobile-block-padding: 16px;

$light-background: #F8F9FC;
$grey-table: #EDF1F7;
$light-grey: #C8CEDA;
$grey: #575F6E;
$dark: #272727;

@function rem ($value) {
	@return #{math.div(math.div($value, $value * 0 + 1), 16)}rem;
}

@mixin h1 {
	font-weight: 700;
	font-size: 32px;
	line-height: 36px;
}

@mixin light {

	[data-mantine-color-scheme='light'] & {
		@content;
	}
}

@mixin dark {

	[data-mantine-color-scheme='dark'] & {
		@content;
	}
}

@mixin hover {
	@media (hover: hover) {

		&:hover {
			@content;
		}
	}

	@media (hover: none) {

		&:active {
			@content;
		}
	}
}

@mixin smaller-than($breakpoint) {
	@media (max-width: $breakpoint) {
		@content;
	}
}

@mixin larger-than($breakpoint) {
	@media (min-width: $breakpoint) {
		@content;
	}
}

// Add direction mixins if you need rtl support

@mixin rtl {

	[dir='rtl'] & {
		@content;
	}
}

@mixin ltr {

	[dir='ltr'] & {
		@content;
	}
}


@mixin apply-primary-dark-btn {

	--button-fz: 16px;

	--button-bg: #{$dark};

	--button-hover: #{$dark};

	--button-padding-x: 24px;
	padding-block: 10px;
	height: auto;
	line-height: normal;
}

@mixin apply-secondary-dark-btn {

	--button-fz: 16px;

	--button-bg: transparent;

	--button-color: #{$dark} !important;

	--button-hover: #{$light-grey};
	padding-block: 10px;
	height: auto;
	line-height: normal;
}

@mixin apply-modal-styles {

	--overlay-bg: rgba(0, 0, 0, 55%);

	--overlay-filter: blur(2.5px);

	--modal-radius: 0;

	--mb-padding: 20px;

	--mantine-radius-default: 0;

	:global(.mantine-Modal-header) {
		padding-inline-end: 20px;
		padding-bottom: 0;
		margin-bottom: 4px;
	}

	:global(.mantine-Modal-title) {
		font-weight: 700;
		font-size: 24px;
		line-height: 20px;
		text-align: center;
		margin-bottom: 24px;
	}

	:global(.mantine-Modal-body) {
		padding-inline: 48px;
	}

	:global(.modal-body-content) {
		margin-bottom: 24px;
	}

	:global(.modal-body-content-text) {
		text-align: center;
	}

	:global(.modal-footer-actions) {
		display: flex;
		gap: 24px;
		justify-content: space-between;

		@include smaller-than($mantine-breakpoint-md) {
			flex-direction: column;
		}
	}

	:global(.modal-footer-action-cancel) {
		@include apply-secondary-dark-btn;
	}

	:global(.modal-footer-action-save) {
		@include apply-primary-dark-btn;
	}
}

@mixin apply-default-input-styles {

	:global(.mantine-InputWrapper-root) {

		--input-asterisk-color: #{$dark};

		--input-label-size: 14px;

	}

	:global(.mantine-InputWrapper-error) {

		--input-error-size: 12px;

	}

	:global(.mantine-InputWrapper-label) {
		font-weight: 400;
		line-height: 17px;
	}

	:global(.mantine-Input-wrapper) {


		&:not([data-with-left-section]) {

			--input-padding-inline-start: 16.5px;
		}

		&:not([data-with-right-section]) {

			--input-padding-inline-end: 16.5px;
		}


		&[data-variant='filled'] {

			--input-bg: #{$light-background};
		}


		--input-size: 50px;

		--input-fz: 16px;


		--input-radius: 8px;

	}
}

@mixin apply-input-styles {
	@include apply-default-input-styles;
}

@mixin apply-multiselect-styles {
	@include apply-default-input-styles;


	:global(.mantine-Input-wrapper) {

		--input-size: auto;
	}

	:global(.mantine-MultiSelect-pill) {

		--pill-radius: 8px;

		--pill-height: 38px;
	}

	:global(.mantine-MultiSelect-inputField) {
		height: 40px;
	}

	:global(.mantine-MultiSelect-input) {
		min-height: 50px;
	}
}

@mixin apply-select-styles {
	@include apply-default-input-styles;
}

@mixin apply-select-dropdown-styles {

	&:global(.mantine-Popover-dropdown) {
		overflow: hidden;
		border: none;

		--popover-radius: 8px;

		--combobox-padding: 0;
		background-color: $grey-table;
	}

	:global(.mantine-Select-option), :global(.mantine-MultiSelect-option) {
		border-radius: 0;

		--combobox-option-fz: 16px;

		--combobox-option-padding: 15.5px 16px;

		&:not(:last-child) {
			border-bottom: 1px solid #D3D7DA;
		}

		@include hover {

			&:not([data-combobox-selected], [data-combobox-disabled]) {
				background-color: $light-grey;
			}
		}
	}
}


@mixin apply-checkbox-styles {

	:global(.mantine-Checkbox-root) {

		--label-fz: 12px;

	}
}


@mixin apply-table-styles {

	:global(.mantine-Table-thead .mantine-Table-tr) {
		border-bottom: none;
		background-color: $grey-table;
	}

	:global(.mantine-Table-th) {

		--table-vertical-spacing: 13px;

		--table-horizontal-spacing: 16px;
		border-bottom: none;
		color: rgba(39, 39, 39, 70%);
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
	}

	:global(.mantine-Table-td) {

		--table-vertical-spacing: 15px;

		--table-horizontal-spacing: 16px;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
	}
}
